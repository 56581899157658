.App {
  text-align: center;
}




.header___3lIJz {
    display: flex;
    height: 250px;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 5px rgba(0,0,0,.7);
    box-shadow: var(--shd,0 0 5px rgba(0,0,0,.7));
    height: 100%;
}
.header___3lIJz .logo___3-F7Q {
    margin-right: 100px;
    font-weight: 700;
    font-size: 32px;
}
.header___3lIJz .nav___1H1Tj {
    display: flex;
    grid-gap: 64px;
    gap: 64px;
    font-size: 35px;
}

a {

    color: #1890ff;
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: color .3s;
    -webkit-text-decoration-skip: objects;
}
.header___3lIJz .logo___3-F7Q .logoImage___3i5XT {
    width: 150px;
    margin-right: 8px;
}
img {
    vertical-align: middle;
    border-style: none;
}
.header___3lIJz .nav___1H1Tj a {
    font-weight: 700;
}


.maina{
  text-align: center;
  font-size: 20px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.drop{
  font-weight: 200px;
  margin: 100px;
  background-color: gray;
  cursor: pointer;
}
.dropa{
  font-weight: 200px;
  margin: 10px;
  cursor: pointer;
}
.main_a{
  height: 100px;
  align-items: center;
  justify-content: center;
  display: flex;
}
.puta{
    margin: 15px;
    outline: none;
    border-radius: 5px;
    padding: 8px 0px;
    width: 250px;
    font-size: 24px;
}
.about{

    height: 1000px;
}
.how{
    background-color: gray;
    height: 50px;
}